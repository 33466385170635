var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "container cl"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5), _vm._m(6), _vm._m(7), _vm._m(8), _vm._m(9), _vm._m(10), _vm._m(11), _vm._m(12), _vm._m(13), _vm._m(14), _vm._m(15), _vm._m(16), _vm._m(17), _vm._m(18), _vm._m(19), _vm._m(20), _vm._m(21), _vm._m(22), _vm._m(23), _vm._m(24), _vm._m(25), _vm._m(26), _vm._m(27), _vm._m(28), _vm._m(29), _vm._m(30), _vm._m(31), _vm._m(32), _vm._m(33), _vm._m(34), _vm._m(35), _c('h3', [_vm._v("Todos")]), _vm._m(36), _c('pre', [_c('h3', [_vm._v("Your device")]), _c('pre', [_vm._v(_vm._s(_vm.mobile))])]), _c('br'), _c('br'), _c('br'), _c('br')]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.42")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.07.2024")]), _c('ul', [_c('li', [_vm._v("Feature: Aufnahmen aus der consense-ai APP können unter dem Reiter \"Aufnahmen\" in der WebApp eingesehen werden")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.41")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12.07.2024")]), _c('ul', [_c('li', [_vm._v("5x Wiederholungen bei fehlerhaftem Upload")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.40")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.06.2024")]), _c('ul', [_c('li', [_vm._v("Vorbereitung für Fehlerfindung beim Hochladen von Dateien")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.39")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("27.06.2024")]), _c('ul', [_c('li', [_vm._v("Allianz Filter MFH St.Florian hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.38")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("20.02.2024")]), _c('ul', [_c('li', [_vm._v("SVs können keinen \"vertraulich\"-Ordner im Zip mehr herunterladen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.37")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("16.01.2024")]), _c('ul', [_c('li', [_vm._v("Dateien: Anzeige von erstellt und geändert Datum/Zeit")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.36")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("16.01.2024")]), _c('ul', [_c('li', [_vm._v("Dateien können nach Erstellungsdatum sortiert werden, auch als Standard festgelegt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.35")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.01.2024")]), _c('ul', [_c('li', [_vm._v("Rücknahme: Gutachten können nicht mehr gesperrt werden.")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.34")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.01.2024")]), _c('ul', [_c('li', [_vm._v("FIX: Bilder können nicht im Fließtext ausgewählt werden")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.33")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12.01.2024")]), _c('ul', [_c('li', [_vm._v("Gutachten sperren: für Admins wieder entsperrbar")]), _c('li', [_vm._v("Bilder Auswahl: Indikator, ob Bilder schon im Gutachten sind, auch die, die nicht unbedingt in der selben Gutachten-Sektion ausgewählt sind")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.32")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.01.2024")]), _c('ul', [_c('li', [_vm._v("Gutachten sperren (WIP: jetzt noch entsperrbar, nach Kontrolle nicht mehr)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.31")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("27.12.2023")]), _c('ul', [_c('li', [_vm._v("Urgenzen-Checkbox: Name geändert von \"Hohe Priorität\" auf \"Urgenz\"")]), _c('li', [_vm._v("Urgenzen-Checkbox: Bei Aktivierung wird automatisch eine Urgenz abgelegt ")]), _c('li', [_vm._v("Urgenzen-Checkbox: Bei Deaktivierung werden automatisch alle Urgenzen gelöscht")]), _c('li', [_vm._v("Bei Statusänderung auf 12,13,14 werden alle Urgenzen gelöscht und die Checkbox deaktiviert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.30")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.12.2023")]), _c('ul', [_c('li', [_vm._v("Urgenzen-Ansicht für Admins und GF (Teil 1: Tabelle)")]), _c('li', [_vm._v("In Arbeit Teil 2: automatische Funktionalitäten mit \"Hohe Priorität\" und Statusänderung und Filterung")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.29")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.12.2023")]), _c('ul', [_c('li', [_vm._v("Ältere Akte laden (Button in der Offene Akte Ansicht)")]), _c('li', [_vm._v("Bei Eintragung eines Besichtigungsdatums werden emails an die Allianz verschickt (ab.1.1.2024 aktiv)")]), _c('li', [_vm._v("Automatische Änderung des Status nach Eintragung des Besichtigungsdatums (auf 5, Voraussetzung: Status war vorher auf 1-4) ")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.28")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.06.2023")]), _c('ul', [_c('li', [_vm._v("FIX: verlorene Änderungen wiederhergestellt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.27")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.06.2023")]), _c('ul', [_c('li', [_vm._v("Adaptionen in Gebäudebewertung")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.26")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.06.2023")]), _c('ul', [_c('li', [_vm._v("FEAT: Gebäudebewertung")]), _c('li', [_vm._v("SVG Logo statt JPG Logo in den Gutachten")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.23")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("31.01.2023")]), _c('ul', [_c('li', [_vm._v("FEAT: Allianz: Sortieren nach \"neu ab 2023\"")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.22")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("1.12.2022")]), _c('ul', [_c('li', [_vm._v("Fix: Problem beim Ändern von Besonderheiten zum Auftraggeber wurde behoben.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.21")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("27.10.2022")]), _c('ul', [_c('li', [_vm._v("Gutachten: Besondere Notizen werden jetzt standardmäßig im Gutachten angezeigt.")]), _c('li', [_vm._v("Gutachten: \"Landwirtschaft\" als Option für Gebäudenutzung.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.20")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.10.2022")]), _c('ul', [_c('li', [_vm._v("Fix: Besichtigungsdatum bei neuen Gutachten wird nun korrekt gesetzt")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.19")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("27.09.22")]), _c('ul', [_c('li', [_vm._v("Fix: Titel wird bei Personendaten in der Akt-Allgemeinansicht angezeigt.")]), _c('li', [_vm._v("Fix: Neuverteilung der Berechtigungen bei Admin-Benutzern")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.18")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12.09.2022")]), _c('ul', [_c('li', [_vm._v("Fix: Feld: Vorschaden; Initialwertsetzung bei neu erstellten Gutachten")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.17")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.08.2022")]), _c('ul', [_c('li', [_vm._v("Fix: Datumsfelder in der Aktansicht werden jetzt in der richtigen Zeitzone angezeigt.")]), _c('li', [_vm._v("Akte: Stornierte Akte werden jetzt im filter für erledigte Akte inkludiert")]), _c('li', [_vm._v("Akte: Notizen, die beim Setzen des Akt-Status gesetzt werden, können jetzt nachträglich editiert werden.")]), _c('li', [_vm._v("Gutachten: Dokumentenanhänge können jetzt wie Bilder sortiert werden.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.15/16")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.07.2022")]), _c('ul', [_c('li', [_vm._v("Fehler behoben: bei manchen Akten kann kein Aufwand eingegeben werden")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.9.0")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.04.2022")]), _c('ul', [_c('li', [_vm._v("Offene Akte: interaktive Tabelle und Detailansicht wurden überarbeitet.")]), _c('li', [_vm._v("Akte: Anzahl eingegangener Urgenzen werden jetzt im Crm und der Webapp abgebildet.")]), _c('li', [_vm._v("Aufwände: Map zur Berechnung der gefahrenen km / zeit wird jetzt auch im Aufwandstab angezeigt.")]), _c('li', [_vm._v("Akte: Statusänderungen werden jetzt im Journal abgelegt, auch wenn sie aus der WebApp passieren.")]), _c('li', [_vm._v("Gutachten: Es muss kein Gutachten angelegt werden, wenn bereits ein Gutachten im Wordformat hochgeladen wurde. Dafür gibt es nun einen eigenen Button")]), _c('li', [_vm._v("Crm: Schadensummen und Einsparungen werden jetzt im Crm abgelegt, wenn die Honorarnote berechnet wird.")]), _c('li', [_vm._v("Gutachten: Seitenumbrüche zwischen Blöcken können jetzt manuell ein- und ausgeschalten werden.")]), _c('li', [_vm._v("Neue Liste mit interaktiver Tabelle")]), _c('li', [_vm._v("Neue Kartenansicht mit angepassten Filtern")]), _c('li', [_vm._v("Download XLSX für CRM Akte")]), _c('li', [_vm._v("Gutachten: max. Anzahl der Bilder auswählbar, mehr werden nicht angezeigt")]), _c('li', [_vm._v("Gutachten: erstellte Gutachten können automatisch zu fertige Berichte kopiert werden")]), _c('li', [_vm._v("Einstellung: präferierte Nachrichten Übermittlung per Email oder WebApp")]), _c('li', [_vm._v("Falls keine Koordinaten zu einer Adresse für CRM gefunden werden können, wird nur die PLZ als Zentrum herangezogen")]), _c('li', [_vm._v("Honorarnote: Polizzennummer durch Auftragsnummer ausgetauscht")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.8.5")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.03.2022")]), _c('ul', [_c('li', [_vm._v("Gutachten: Änderungskonflikte werden nun erkannt. Der Benutzer bekommt eine Warnung, wenn durch eine manuelle oder automatische Speicherung Daten verloren gehen würden.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.8.2")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.03.2022")]), _c('ul', [_c('li', [_vm._v("Atke: Performanceverbesserungen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.8.1")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("01.03.2022")]), _c('ul', [_c('li', [_vm._v("(Bugfix: Memory-Leak in Bearbeitung) Nachrichten optimiert ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.8.0")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.02.2022")]), _c('ul', [_c('li', [_vm._v("Emails: Cc, Bcc und eine Dringend-Markierung können jetzt im Emailformular gesetzt werden")]), _c('li', [_vm._v("Akte: Allgemein-Tab ist jetzt an Position 1 in der Tabsortierung")]), _c('li', [_vm._v("Gutachten: Titel ist jetzt als \"Gutachterliche Stellungnahme\" vorausgewählt, für Fälle von Experte als \"Expertise\"")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.7.11")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.02.2022")]), _c('ul', [_c('li', [_vm._v("Akte: Office kann jetzt Emails direkt aus der Akt-Detailansicht verschicken. Die Emails werden automatisch im Sharepoint unter dem gewählten Ordner abgelegt und Dokumente können direkt aus dem Sharepointordner des Aktes angehängt werden.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.7.7")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.02.2022")]), _c('ul', [_c('li', [_vm._v("Akte: \"Erhöhungsanfrage\" kann jetzt aus der WebApp gesetzt werden.")]), _c('li', [_vm._v("Akte: Nachrichten für eine Statusänderung auf Status 74 werden jetzt an die Gruppe \"Gutachten Versenden\" geschickt.")]), _c('li', [_vm._v("Benachrichtigungen: Nachrichtengruppen sind jetzt in Tabs organisiert, um die Ansicht übersichtlicher zu gestalten.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.7.6")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("01.02.2022")]), _c('ul', [_c('li', [_vm._v("Akte: Wenn beim Versenden einer Nachricht der Status geändert wird, wird diese Änderung nun automatisch gespeichert.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.7.0")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.01.2022")]), _c('ul', [_c('li', [_vm._v("Akte: Der Ablauf von Status 72 bis 74 ist jetzt über einen Expliziten Statusablauf geregelt. Ein Akt in diesem Statusbereich führt im Header der Detailansicht eine Statusübersicht, mit welcher der Akt auf den nächsten Status weitergereicht werden kann; optional mit Notizen für den nächsten Schritt. Die Notizen werden seitlich neben den Aktinformationen angezeigt.")]), _c('li', [_vm._v("Akte: Im Journal können jetzt Notizen zum Akt eingegeben werden, welche ebenfalls in der Notitzübersicht aufgelistet werden.")]), _c('li', [_vm._v("Akte: Der Button 'Akt Versenden und Verrechnen' setzt jetzt automatisch den Status des Akts auf 72.")]), _c('li', [_vm._v("Nachrichten: Nachrichten aus dringenden Akten werden jetzt in der Nachrichtenliste/Inbox hervorgehoben.")]), _c('li', [_vm._v("Honorarnoten: Die automatischen Pauschalbeträge werden jetzt standardmäßig für den Haupt-SV angelegt, statt für das Sekreteriat ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.12")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.12.2021")]), _c('ul', [_c('li', [_vm._v("Fehler behoben, bei dem die Nachrichten und Nachrichtenvorlagen verschwiden")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.10")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.12.2021")]), _c('ul', [_c('li', [_vm._v("Allgemein: Die Schriftgröße in der App wurde angehoben.")]), _c('li', [_vm._v("Aufwände: \"Fahrtkosten\"-Aufwand ist nun bei den vorbereiteten Aufwänden enthalten")]), _c('li', [_vm._v("Nachrichten: Nachrichten können nun alternativ auch per E-Mail empfangen werden. Dies kann unter den Einstellungen aktiviert werden.")]), _c('li', [_vm._v("Honorarnote: \"Büro\"-Aufwände werden nun auch ans Ende der Auflistung sortiert.")]), _c('li', [_vm._v("Fix: In der Aufwandskontrolle erstellte Aufwände müssen nun nicht mehr doppelt gespeichert werden.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.9")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("16.12.2021")]), _c('ul', [_c('li', [_vm._v("Akte: Dringende Akte werden nun in der Liste und der Detailansicht gekennzeichnet.")]), _c('li', [_vm._v("Akte: Ein neues Feld \"Hohe Priorität kann nun gesetzt werden. Es ersetzt den Status \"Urgenz\"")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.8")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.12.2021")]), _c('ul', [_c('li', [_vm._v("Allianz: PDF-Link für Bericht hinzugefügt")]), _c('li', [_vm._v("ad) Allianz: zusätzlich PDF-Link für Dateien hinzugefügt")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.7")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.12.2021")]), _c('ul', [_c('li', [_vm._v("Allianz: Bug: Karte wieder sichtbar")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.6")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.12.2021")]), _c('ul', [_c('li', [_vm._v("Allianz: Begehungsdatum wird jetzt richtig befüllt ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.3")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.12.2021")]), _c('ul', [_c('li', [_vm._v("Akte: Header ist jetzt fixiert und immer sichtbar.")]), _c('li', [_vm._v("Sharepoint: EML-Dateien werden jetzt in der Vorschau angezeigt.")]), _c('li', [_vm._v("Aufwandskontrolle: Der aktuelle Akt wird nun im Routenplaner der Aufwandskontrolle mit angezeigt.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.2")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("01.12.2021")]), _c('ul', [_c('li', [_vm._v("Gutachten: Besichtigungsdatum kann nun in der Stellungnahme angegeben werden. Schadendatum wird nun mit ins PDF übernommen.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.6.0")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.11.2021")]), _c('ul', [_c('li', [_vm._v("App: Anwender können nun einem Akt mit einer Farbei markieren. Diese ist nur für den Benutzer sichtbar und hebt den Akt in der Übersicht und der Detailansicht farblich hervor.")]), _c('li', [_vm._v("App: Ungeöffnete Akte werden einem Benutzer in der Übersichtsliste mit einem geschlossenen Briefsymbol angezeigt.")]), _c('li', [_vm._v("App: Admins haben nun auf die gleichen Nachrichtenvorlagen zugriff wie ein SV, wenn sie SV des Aktes sind, aus dem die Nachricht versendet wird. ")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.5.4")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.10.2021")]), _c('ul', [_c('li', [_vm._v("App: Aufwände aus der WebApp werden nun wieder korrekt erstellt.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.4.4")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.07.2021")]), _c('ul', [_c('li', [_vm._v("App : Updates der Applikation werden nun korrekt vorgeladen. Der Update-Prompt funktioniert nun korrekt.")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.3.14")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.06.2021")]), _c('ul', [_c('li', [_vm._v("CRM : Pdfs werden in der Vorschau vorgeladen")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_vm._v("...")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.115b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.03.2021")]), _c('ul', [_c('li', [_vm._v("Allianz : \"Mehraufwand\"-Filter hinzugefügt")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.115a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.03.2021")]), _c('ul', [_c('li', [_vm._v("Allianz : \"Mehraufwand\"-Reiter hinzugefügt")]), _c('li', [_vm._v("Allianz : nach Denkmalschutz filtern")]), _c('li', [_vm._v("Allianz : Mehraufwand und Denkmalschutz in Export")]), _c('li', [_vm._v("CRM Dateiexplorer sortieren nach Änderungsdatum")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.115")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.03.2021")]), _c('ul', [_c('li', [_vm._v("Allianz : abgeschloßen Datum hinzugefügt")]), _c('li', [_vm._v("CRM Sharepoint Api verbessert")]), _c('li', [_vm._v("CRM neuer verbesser Dateiexplorer (Kachelansicht, Mehrfachauswahl zum Löschen, Sortierfunktion, PDF Preloader, ...)")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.114a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.02.2021")]), _c('ul', [_c('li', [_vm._v("CRM : Nachrichten : Ältere Nachrichten nachladbar")]), _c('li', [_vm._v("Allianz : Suchfunkction verbessert (ohne Enter)")]), _c('li', [_vm._v("Allianz : Statistik: Wochen ausgebessert")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.114")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.02.2021")]), _c('ul', [_c('li', [_vm._v("CRM : Aufwand : neue Tatigkeiten beide Fremdkosten")]), _c('li', [_vm._v("CRM : Allgemein : Notizen hinzufügen und ändern möglich")]), _c('li', [_vm._v("CRM : Gutachten : UST incl./excl in Stellungnahme")]), _c('li', [_vm._v("Allianz : Export Feld \"Dringend\" hinzugefügt")]), _c('li', [_vm._v("Allianz : Sortieren nach abgeschloßenen Berichten möglich")]), _c('li', [_vm._v("Allianz : Text Formatierung in Allianz Notiztexten")]), _c('li', [_vm._v("Allianz : Denkmalschutz Felder überarbeitet")]), _c('li', [_vm._v("Allianz : SV in Karte an Markern sichtlich")]), _c('li', [_vm._v("Allianz : Karte kann nach SV gefiltert werden")]), _c('li', [_vm._v("Allianz : \"Tabelle anpassen\" wird local gespeichert")]), _c('li', [_vm._v("Allianz : Wochen in Statistik chronologisch sortiert")]), _c('li', [_vm._v("Allianz : detaillerter Filter - mehrere Elemente getrennt mit \";\" können gesucht werden")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.113a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.02.2021")]), _c('ul', [_c('li', [_vm._v("CRM: Allgemein : Notizen hinzufügen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.113")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04.02.2020")]), _c('ul', [_c('li', [_vm._v("CRM: Aufwand : SV sieht Stunden summiert")]), _c('li', [_vm._v("CRM: AufwandKontrolle : SV Verwaltung für die Akte aus der Webapp heraus")]), _c('li', [_vm._v("CRM: AufwandKontrolle : Button für alle automatisch 'übernehmen'")]), _c('li', [_vm._v("CRM: AufwandKontrolle : neuer Name Sekretariat in Tätigkeitsprotokoll")]), _c('li', [_vm._v("CRM: AufwandKontrolle : SV Nachname alphabetisch soriert")]), _c('li', [_vm._v("CRM: AufwandKontrolle : SV nur zum Akt zugeordnete auswählbar")]), _c('li', [_vm._v("CRM: AufwandKontrolle : Anzeige von anderen Akten, die das selbe Besichtigungsdatum haben")]), _c('li', [_vm._v("CRM: AufwandKontrolle : neue Buttons für Reise.Sach.GA. ODER Sach.GA.")]), _c('li', [_vm._v("CRM: Tätigkeitsprotokoll : wird nun nach Datum sortiet")]), _c('li', [_vm._v("CRM: HN : '1' statt '1x' in Pauschal")]), _c('li', [_vm._v("CRM: HN : HN Datum immer gleich Ersterstellung und nicht mehr änderbar")]), _c('li', [_vm._v("CRM: HN : automatisch auf 'neu' hüpfen nach HN Erstellung")]), _c('li', [_vm._v("CRM: HN : alle Pauschale werden in HN summiert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.112a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Notizen hinzugefügt")]), _c('li', [_vm._v("- CRM: Gutachten: AST automatisch übernommen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.112")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Nicht-Crm-User können durch \"SV-Zuordnung\" zu CRM Akten zugeordnet werden")]), _c('li', [_vm._v("- CRM: Strassenzusatz bei Besichtigungsadresse")]), _c('li', [_vm._v("- CRM: Besichtigungsadresse Koordinaten hinzugefügt")]), _c('li', [_vm._v("- CRM: Eigene Bezeichnung für Akte möglich")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.111")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("16.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Stellungnahme: USt. inc/exc. Einstellung möglich")]), _c('li', [_vm._v("- CRM: Geschuädigte im \"Allgemein\"-Teil aufgelistst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.110")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GA: Typo (angehägt -> angehängt)")]), _c('li', [_vm._v("- CRM: GA: Kostentabelle auswählbar in Gutachten, ob diese ins PDF kommt")]), _c('li', [_vm._v("- CRM: Nachrichten: abgeschloßene Nachrichten nach einem Tag raus!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.109")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GA: MFG Block bei Stellungnahme Anpassung möglich")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.108")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.12.2020")]), _c('ul', [_c('li', [_vm._v("- ALLGEMEIN: nur noch einmal in 14 Tagen anmelden notwendig")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.107")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Nachrichten bis 14 Tage nach Erstellung anzeigen")]), _c('li', [_vm._v("- CRM: Akte: kleinere Bugfixes")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.106b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("07.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: HN: Falsche Adresse bei Referent behoben")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.106a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("05.12.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Fehler behoben in \"neue Felder in Betriebsart\"")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.106")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: kleinere Anpassungen")]), _c('li', [_vm._v("- CRM: Dateien Download Link geht wieder")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.105")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Nachrichten angepasst")]), _c('li', [_vm._v("- CRM: HN angepasst (Rechnungszeile!)")]), _c('li', [_vm._v("- CRM: Aktliste ohne HN-erstellt! (Filter)")]), _c('li', [_vm._v("- CRM: Gutachten als Nachricht verschicken")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.104")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("21.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: HN angepasst")]), _c('li', [_vm._v("- CRM: Nachrichten angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.102,1.2.103")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17-19.12.2020")]), _c('ul', [_c('li', [_vm._v("- HN angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.101")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("16.12.2020")]), _c('ul', [_c('li', [_vm._v("- User Tabelle angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.101")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.12.2020")]), _c('ul', [_c('li', [_vm._v("- Anpassungen in der HN Erstellung")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.100")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12-14.12.2020")]), _c('ul', [_c('li', [_vm._v("- ⭐ Nachrichten Pool hinzugefügt (erste Versuchsversion!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.99")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("8-12.12.2020")]), _c('ul', [_c('li', [_vm._v("- ⭐ CRM HONORARNOTEN erstellen hinzugefügt!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.98b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("07.12.2020")]), _c('ul', [_c('li', [_vm._v("- weitere SERVER Updates (HONORARNOTEN UND KOSTENBLÖCKE)")]), _c('li', [_vm._v("- kleinere Beschleunigungen in der WebApp")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.98a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("05.12.2020")]), _c('ul', [_c('li', [_vm._v("- SERVER Updates")]), _c('li', [_vm._v("- CRM: HONORARNOTEN UND KOSTENBLÖCKE Vorbereitungen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.98")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("01.12.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: HONORATNOTEN: Honorarnoten können erstellt werden (TESTVERSION!!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.97a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("29.11.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz: HV: müssen Office kontaktieren um Dateien zu löschen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.97")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.11.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz: BATCH: E0 löst nicht mehr automatisch \"Massenermittlung=true\" aus")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.96")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.11.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: AUTOSAVE: nach User , nicht nach Login-session gespeichert - standard: \"true\" = aktiviert")]), _c('li', [_vm._v("- CRM: Warnung, wenn Gutachten nicht gespeichert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.95a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.11.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: BERICHT: keine leeren Begriffe in Felder übernemhen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.95")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.11.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN: Option für kleineren \"mit freundlichen Grüßen\" Block am Ende jedes Gutachtens")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.94a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.11.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: ALLGEMEIN: Anzeige für freigegebene User")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.94")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.11.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: AUFWAND: GF Eingaben mit 7 Punkten HN")]), _c('li', [_vm._v("- CRM: GUTACHTEN: Autosave")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.93")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04.11.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Status: SV2 kann angepasst werden")]), _c('li', [_vm._v("- ALLIANZ: \"Tabelle anpassen\" fixiert (bleibt nach Reload erhalten)")]), _c('li', [_vm._v("- ALLIANZ: Sammelaktion mit auto. MassErm. bei E0 Status")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.92")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.11.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: AUFWAND: SV Tabelle vorbereitet")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.91")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("01.11.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: AUFWAND: GF kann Aufwände ändern/genehmigen/übernehmen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.90")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN: Befundaufnahme: Wohnungen-Mehrfachauswahl")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.89")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("20.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN: Button zum kopieren von GA odser SN")]), _c('li', [_vm._v("- CRM: GUTACHTEN: Bilder automatisch aufklappen, wenn PDF aktiviert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.88")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: DATEIN: neu laden Button hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.87")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GA LANG : Kontakte anklickbar")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.86")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Stellungnahme hinzugefügt")]), _c('li', [_vm._v("- CRM: Stellungnahme/GA: alle Teil-PDFs separat gespeichert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.85")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.10.2020")]), _c('ul', [_c('li', [_vm._v("- ⭐ CRM: GUTACHTEN Bilder rotieren")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.84")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.10.2020")]), _c('ul', [_c('li', [_vm._v("- ⭐ CRM: GUTACHTEN LANG hinzugefügt")]), _c('li', [_vm._v("- CRM: GUTACHTEN kleinere Updates und Verbesserungen ( mehrere GA möglich, Nummerierung, neue Blöcke,...)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.83")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("8.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten: Versionskontrolle")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.82")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("5.10.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten: Vordefiniertes Layout")]), _c('li', [_vm._v("- CRM: Gutachten: Bilder: Qualitätseinstellungen")]), _c('li', [_vm._v("- CRM: Gutachten: Design Anpassungen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.81")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("2.10.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Bezahl Status in Liste aufgeräumt")]), _c('li', [_vm._v("- ALLIANZ: Filter in Liste angepasst (s1 sucht nur s1, nicht s13 und \"s1,\" sucht alle mit s1 beginnend)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.80")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Berichtfelder (z.B. Baujahr) selbst schreiben möglich (unabhängig von der Auswahl)")]), _c('li', [_vm._v("- ALLIANZ: Denkmalschutz in Berich aus \"Allgemein\"-Reiter übernommen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.79")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("29.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Denkmalschutz als Filter")]), _c('li', [_vm._v("- ALLIANZ: Export T1 und Z1 getrennt")]), _c('li', [_vm._v("- ALLIANZ: Karte - T1 und Z1 anwählbar")]), _c('li', [_vm._v("- ALLIANZ: Filter \"Urgenz\" und \"DS\" (für Sv2 sichtbar)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.78")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Denkmalschutz als Export")]), _c('li', [_vm._v("- ALLIANZ: Denkmalschutz Felder hinzufügen und löschen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.77")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Denkmalschutz zum Export hinzugefügt")]), _c('li', [_vm._v("- ALLIANZ: Denkmalschutz automatisch bei S6 auf \"JA\" gesetzt")]), _c('li', [_vm._v("- ALLIANZ: Denkmalschutz Reiter mit Tabelle")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.76")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Denkmalschutzregler hinzugefügt")]), _c('li', [_vm._v("- ALLIANZ: fertige Berichte Änderungen nur bei geänderten Berichten, nicht nach generell geänderten Akt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.75a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Juni2020 umbenannt zu Urgenz/wichtig")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.75")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.09.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten - bei PDF Erstellen wird automatisch der Stand gespeichert.")]), _c('li', [_vm._v("- CRM: Gutachten - Kostentabelle angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.74b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: SV2 kann bearbeiten wenn Akt auf T0 gesetzt wird!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.74a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.09.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Farben Fehler und Bilder durchklicken behoben!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.74")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("07.09.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: kleinere Verbesserungen bei Uploads")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.73")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.09.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten: Blocksatz korrigiert")]), _c('li', [_vm._v("- CRM: Gutachten: Liste als Formatierung hinzugefügt")]), _c('li', [_vm._v("- CRM: Gutachten: Bilder verschiebbar")]), _c('li', [_vm._v("- CRM: Gutachten: Kostentabelle korrigiert beim Löschen von Zeilen")]), _c('li', [_vm._v("- CRM: Gutachten: Kostentabelle Zeilen tauschenbar")]), _c('li', [_vm._v("- CRM: Gutachten: Leere Felder im PDF nicht darstellen (korrigiert)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.72")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.09.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz: Offline Bilder Speicher beschleunigt!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.71")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("29.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: AKT Liste mit Adresse")]), _c('li', [_vm._v("- CRM: Gutachten Fehler-PDF bei fehlenden Anlagen behoben")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.70")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.08.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Offline Speicher Schalter als Versuch!")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.69")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten: druchgestrichen entfernt . Rot markieren hinzugefügt")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.68a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.08.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: fix Zip download")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.68")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.08.2020")]), _c('ul', [_c('li', [_vm._v("- Allgemeine Server Updates")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.67a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("21.08.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ SV2 können T0 lesen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.67")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("20.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN Versicherungsnehmer mit Vornamen & Titel")]), _c('li', [_vm._v("- CRM: GUTACHTEN Sonstiges / Gebäude / Inhalt bewertet")]), _c('li', [_vm._v("- CRM: GUTACHTEN MwSt. zu USt. (auch im pdf)")]), _c('li', [_vm._v("- CRM: GUTACHTEN Neuwert/Zeitwert änderbar")]), _c('li', [_vm._v("- CRM: GUTACHTEN Schadenmassnahmen \"nicht notwendig\" hinzugefügt")]), _c('li', [_vm._v("- CRM: GUTACHTEN Bilder Nummerierung")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.66")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Aufwandsbearbeitung vorbereitet")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.65")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Offene Akte nach Aktnummer sortiert")]), _c('li', [_vm._v("- CRM: Dateien: PDF mit Bildern können seitlich gescrollt werden")]), _c('li', [_vm._v("- CRM: Bilder-Vorschau: ALT Taste muss gedrückt sein um zu zoomen")]), _c('li', [_vm._v("- CRM: Gutachten: Datum gefixt")]), _c('li', [_vm._v("- CRM: Gutachten: KontoNr.+ Ablöse optional darstellbar")]), _c('li', [_vm._v("- CRM: Gutachten: überall Blocksatz")]), _c('li', [_vm._v("- CRM: Dateien : Support für html,htm,tif Dateien")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.64")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten Anhänge repariert + kleinere Updates")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.63")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten Texte formatieren: Fett/Kursiv/Unterstrichen/Durchgestrichen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.62")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Anhänge an Gutachten möglich")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.61")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.08.2020")]), _c('ul', [_c('li', [_vm._v("- diverse Updates")]), _c('li', [_vm._v("- Test für MS Surface für Offline Speicher")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.60")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.08.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: sichtbare Akte Filter (nach Excel Vorlage)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.59")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Gutachten Bilder Reihenfolge unabhängig")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.58")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.08.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Updates für User Rechte")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.57")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("31.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Runtergeladene Berichte können vorgemerkt werden, dass diese schon runtergeladen worden sind")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.56")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("31.07.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Erst Info Text Feld kann befüllt werden und eine automatische email ans office@consense.at wird versendet")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.55")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Standardmäßig werden nur A0 mit >3mio eingeblendet")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.54")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.07.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN updates - Titel vor Namen hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.53")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("29.07.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN updates")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.52")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("27.07.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN updates")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.51")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.07.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: GUTACHTEN erstellen: erster Test")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.50")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: SpeicherButton verschwindet nach dem Speichern")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.49")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: meine-Akte-Filter, wo User nur Sv1 ist")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.48")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: verbesserter XLS Export (leichter verständlich und immer gleich geordnet)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.47")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.07.2020")]), _c('ul', [_c('li', [_vm._v("- DEVELOP: Automatisches Deployment über gitlab")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.46")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: leere Felder in Außenanlagen werden nicht mehr dargestellt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.45")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: XLS Export Checkboxes aufgeräumt")]), _c('li', [_vm._v("- ALLIANZ: XLS Export umgeschrieben(Code aufgrräumt)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.44")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: \"SV bezahlt\" in XLS Export hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.42/43")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("05.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: \"bearbeitet\"-Spalte in XLS")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.41")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Textfelder passen Höhe automatisch an")]), _c('li', [_vm._v("- FIX: Fehler bei mehreren Tabs - Firebase-Syncro gelöst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.40")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("01.07.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: T1 zu \"fertige Akte XLS\" hinzugefügt")]), _c('li', [_vm._v("- ALLIANZ: T1: SV Rechnung begleichen")]), _c('li', [_vm._v("- ALLIANZ: keine Notizen bei der Suche")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.39b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("27.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Speicher button scheint nach Speichern nicht mehr auf")]), _c('li', [_vm._v("- ALLIANZ: Journal separat aufgerufen (schnellere Aufrufe aufgrund der Datenmenge)")]), _c('li', [_vm._v("- ALLIANZ: Dateien zeigen Größe und Anzahl der Dateien an")]), _c('li', [_vm._v("- ALLIANZ: größeres Notizfeld/kleinere Schrift")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.39a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Typo: Klimasplitgeräte")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.39")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: USER von 50 auf 250 hochgestuft")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.38")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.06.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Emails werden manchmal nicht angezeigt - Verbesserungs-Versuch")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.37")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.06.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: automatische User-Berechtigung beim Zip Download")]), _c('li', [_vm._v("- CRM: Umbenennung beim Hochladen verbessert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.36a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: nach Juni 2020 filtern")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.36")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: verbesserter Offline Speicher")]), _c('li', [_vm._v("- ALLIANZ: NEUE Beauftragung ab Juni: Checkbox+ PolNr. eingekastelt")]), _c('li', [_vm._v("- ALLIANZ: Neue V.Summe in xls und in Übersichtsliste")]), _c('li', [_vm._v("- CRM: Download link prominenter")]), _c('li', [_vm._v("- CRM: FIX: CRM Fileexplorer ändert nach Rename/DeleteFile den Ordner nicht mehr")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.35")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: SV2 Notizfeld (Kontrolle)")]), _c('li', [_vm._v("- ALLIANZ: XLS-Export - Allianzfelder hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.34")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Offline speicher - Fehlerbehebungen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.33")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.06.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Daten komprimiert, weniger Offlinespeicher Belastung!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.32")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.06.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: mehrere Verbesserungen im Sharepoint Teil (Dateien beim hochladen umbenennen, Ornder beibehalten nach Dateien hochladen,...)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.31")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.05.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz: fertige Berichte mit LD Filter")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.30")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.05.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz: Checkboxes für ADMIN: Sv/Allianz gezahlt/Rechnung gestellt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.29")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("21.05.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz SV/Status-Statistik")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.28")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.05.2020")]), _c('ul', [_c('li', [_vm._v("- CRM Dateien: Pdf als Standardansicht")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.27b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.05.2020")]), _c('ul', [_c('li', [_vm._v("- CRM Dateien als Sharepoint-Dateien anzeigen")]), _c('li', [_vm._v("- CRM Fix: erste Datein wird nun auch angezeigt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.27a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.05.2020")]), _c('ul', [_c('li', [_vm._v("- FIX: ALLIANZ: SV PLUS / SV2 kann jetzt Office Notizen lesen")]), _c('li', [_vm._v("- FIX: ALLIANZ: VN Original in Bericht übernehmen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.27")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.05.2020")]), _c('ul', [_c('li', [_vm._v("- ALLIANZ: Abgeschlossene Akte für Admin downloadbar")]), _c('li', [_vm._v("- CRM: ONE-DRIVE Fix")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.26")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04.05.2020")]), _c('ul', [_c('li', [_vm._v("- Dateien Upload : outlook MSG werden kopiert, nicht mehr gelöscht!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.25")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04.05.2020")]), _c('ul', [_c('li', [_vm._v("- ZIP-Downloads für Allianz und CRM Daten")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.24")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.05.2020")]), _c('ul', [_c('li', [_vm._v("- GENERAL IMPROVEMENT: schnellere/selektierte Aufrufe (Daten werden je nach Anforderung geladen und nicht \"immer alle\")")]), _c('li', [_vm._v("- Allinaz: Kopfteil fixiert im Akt")]), _c('li', [_vm._v("- Allinaz: Warnung vor dem Abschließen anzeigen (pdf-Kontrolle!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.22/23")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.04.2020")]), _c('ul', [_c('li', [_vm._v("- MultiTab-Anmelde-Problem gelöst (experimentel)")]), _c('li', [_vm._v("- \"ganzer Screen\" statt SP Daten (experimentel)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.21")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.04.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Sharepoint Zugriff erteilen (jeder CRM-SV kann es für seine eigenen Akte den Zugriff zum SP erlauben)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.20a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.04.2020")]), _c('ul', [_c('li', [_vm._v("- FIX: DateienV2 Zip behoben")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.20")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.04.2020")]), _c('ul', [_c('li', [_vm._v("- Pdf Regler nur für admins")]), _c('li', [_vm._v("- Journal angepasst (besserer Leserlichkeit)")]), _c('li', [_vm._v("- Allianz Felder: Steintreppe mit schmiedeeisernem Geländer")]), _c('li', [_vm._v("- Allianz Felder: diverse Bodenbeläge (Wohnungen)")]), _c('li', [_vm._v("- Allianz Bericht: Begehung automatisch übernehmen (aus SV Status)")]), _c('li', [_vm._v("- Allianz Akt: bei SV2 wird ab Status T1 weitere Änderung nicht gespeichert werden können")])]), _c('h4', [_vm._v("V 1.2.19")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.04.2020")]), _c('ul', [_c('li', [_vm._v("- AllianzDateien V2 (noch in Entwicklung!)")]), _c('li', [_vm._v("- OneDrive Link angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.18")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.04.2020")]), _c('ul', [_c('li', [_vm._v("- CRM: Fix: keine \"ganze Website\" in sharepoint ( Experimentel! )")]), _c('li', [_vm._v("- CRM: TODO: Bilder zommen / Website nicht mitscrollen")]), _c('li', [_vm._v("- CRM: TODO: Bei mehreren offenen Tabs (CRM-Akt mit Sharepoint oder die Webbapp selbst) gibts noch Login-Daten-Überlagerungs Probleme")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.17")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("20.04.2020")]), _c('ul', [_c('li', [_vm._v("- neue Felder im Bericht: \"nicht bekannt!\", und weitere")]), _c('li', [_vm._v("- Textänderung im PDF (ermittlet -> durchgeführt)")]), _c('li', [_vm._v("- WIKI-Seite eingefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.16")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.04.2020")]), _c('ul', [_c('li', [_vm._v("- Server Sicherheitsupdates + IP-Blocker")]), _c('li', [_vm._v("- neuer Datei Exporer in Vorbereitung (Kachel Ansicht)")]), _c('li', [_vm._v("- Aufrufe syncronisiert (geht jetzt schneller!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.15")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.04.2020")]), _c('ul', [_c('li', [_vm._v("- SV in \"Fertige Berichte\"")]), _c('li', [_vm._v("- Fix: Bilder mit Klammern werden akzeptiert")]), _c('li', [_vm._v("- Fix: Z1 in Sammelaktion stellt automatisch \"Akt fertiggestellt\" ein")]), _c('li', [_vm._v("- Fix: fertige Berichte SV-Filtern")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.14")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.04.2020")]), _c('ul', [_c('li', [_vm._v("- Batch/Export für Exteren SV rausgenommen")]), _c('li', [_vm._v("- SV2 ins Batch eingefügt")]), _c('li', [_vm._v("- \"Neu laden\" - Lade-Spinner hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.13")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.04.2020")]), _c('ul', [_c('li', [_vm._v("- Server updates: IP-Adressen Check, Performance-Refactors, Cronjobs(delete old files), sonstige Anpassungen, Bugfixes...")]), _c('li', [_vm._v("- Allianz: Filter angepasst")]), _c('li', [_vm._v("- Allianz: Kontakte-Tabelle Design angepasst")]), _c('li', [_vm._v("- CRM SV kann Recht haben den Ordner \"vertraulich\" zu sehen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.12")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("05.04.2020")]), _c('ul', [_c('li', [_vm._v("- Neuer Fileexplorer im CRM")]), _c('li', [_vm._v("- Sharepoint: msg-Dateien Vorschau")]), _c('li', [_vm._v("- Sharepoint: kleine Vorschaubilder")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.11")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.04.2020")]), _c('ul', [_c('li', [_vm._v("- Neue Admin Rechte: darf Ordner \"vertraulich\" bearbeiten (nur vom GF aktivierbar!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.10b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.04.2020")]), _c('ul', [_c('li', [_vm._v("- Sharepoint - Ordner sortiert nach Namen")]), _c('li', [_vm._v("- Sharepoint - Dateien sortiert nach \"Zeit zuletzt geändert\"")]), _c('li', [_vm._v("- Sharepoint - Dateien umbenennen eingefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.10a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("31.03.2020")]), _c('ul', [_c('li', [_vm._v("- Allianz Bericht: \"nicht bekannt\" bei Geschossen")]), _c('li', [_vm._v("- Allianz Akt Karten hinzugefügt: KANIS WIEN")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.10")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.03.2020")]), _c('ul', [_c('li', [_vm._v("- Experimentel \"CRM Aufgaben\" eingefügt")]), _c('li', [_vm._v("- PDF Regler hinzugefügt")]), _c('li', [_vm._v("- \"E0\" for SV2 freigegeben")]), _c('li', [_vm._v("- \"E0\" automatisch Pdf Regler (Massenermittlung nach Grundbuchauszug) aktivieren, aber manuell deaktivieren!")]), _c('li', [_vm._v("- Sharepoint files korrigiert (Download-Link angepasst und docx Vorschau eingeschalten)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.9a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.03.2020")]), _c('ul', [_c('li', [_vm._v("- anzeigen der Akte-Anzahl im HV Link")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.9")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.03.2020")]), _c('ul', [_c('li', [_vm._v("- Listen-Ansicht im Akt (ermöglicht \"einfaches Durchklicken\")")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.8")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.03.2020")]), _c('ul', [_c('li', [_vm._v("- User Rechte adaptiert: \"Nicht zugeordnete Akte lesen/schreiben\" = SV PLUS")]), _c('li', [_vm._v("- Anzahl in Tabelle: \"Akte pro Seite\" wird zwischengespeichert")]), _c('li', [_vm._v("- Darstellung in \"fertige Akte\" angepasst, Sortierung nach \"zuletzt geändert\"")]), _c('li', [_vm._v("- Z1 nur mehr von admins editierbar, nicht von SVs")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.7")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.03.2020")]), _c('ul', [_c('li', [_vm._v("- Z1 Filter in \"Meine Liste\"")]), _c('li', [_vm._v("- SV-Spalten Filter kann jetzt auch nach SV2 filtern in \"Meine Liste\"")]), _c('li', [_vm._v("- Externe SVs (=SV2) können nur Status \"E1 H6 H8 H9 S4 S3 E2\" bearbeiten - und \"T1\" nur lesen mit Warnung, dass das Speichern nicht mehr möglich ist. alles \"nach T1\" wird der externe SV auch nicht mehr lesen können")]), _c('li', [_vm._v("- Burgenland Kartendienst-Link ausgebessert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.6")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.03.2020")]), _c('ul', [_c('li', [_vm._v("- bearbeitender SV eigene Status-Änderungen eingefügt:"), _c('ul', {
    staticClass: "padding"
  }, [_c('li', [_vm._v("- ab Status (E1 H6 H8 H9 ) werden weitere Status vom SV automatisch angepasst (Besichtigung/Massenermittlung/EHWErmittlung)")]), _c('li', [_vm._v("- Besichtigt & NICHT Massen ermittelt: S3")]), _c('li', [_vm._v("- Besichtigt & Massen ermittelt: S4")]), _c('li', [_vm._v("- NICHT Besichtigt & Massen ermittelt: E2")]), _c('li', [_vm._v("- BereitfürEHW : T1")]), _c('li', [_vm._v("- ab T1 gibts eine Warnung, damit keine weitere Bearbeitung durchgeführt werden soll, ist jedoch nicht gesperrt!")])])])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.5")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.03.2020")]), _c('ul', [_c('li', [_vm._v("- Layout für pdf angepasst")]), _c('li', [_vm._v("- Typo \"Faassade\" behoben")]), _c('li', [_vm._v("- Zoom am Tablet für Bilder (Allianz+CRM)")]), _c('li', [_vm._v("- durchklickbare Vorschau Ansicht für Allianz-Akte")]), _c('li', [_vm._v("- HV Link in Akt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.4a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04.03.2020")]), _c('ul', [_c('li', [_vm._v("- CRM Liste in richtiger Reihenfolge angezeigt")]), _c('li', [_vm._v("- SV2 Status werden nicht noch angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.4")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("29.02.2020")]), _c('ul', [_c('li', [_vm._v("- SV kann \"Sonstige Infos\" lesen, aber nicht editieren")]), _c('li', [_vm._v("- \"Nur meine Akte\"-Filter in Allianz Liste")]), _c('li', [_vm._v("- Im Bericht Kopfteil mehrere Bilder reaktiviert")]), _c('li', [_vm._v("- PDF - Vorlage angepasst: bessere Verteilung der Titlebilder")]), _c('li', [_vm._v("- Offline Bilder beschleuigt, dafür Vorschau entfernt")]), _c('li', [_vm._v("- Vorbereitungen für automatisierte SV2-Status")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.3b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.02.2020")]), _c('ul', [_c('li', {
    staticClass: "done"
  }, [_vm._v("- Bericht: Im Kopfteil wird nur ein Bild akzeptiert")]), _c('li', [_vm._v("- Fehler behoben: Excel Variante 3 Zahlen/Text Problem behoben")]), _c('li', [_vm._v("- Fehler behoben: HV wieder in Liste")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.3a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.02.2020")]), _c('ul', [_c('li', [_vm._v("- Fehler behoben: Links in Dateien")]), _c('li', [_vm._v("- \"Bild rotieren\" in allen Bilder immer sichtbar")]), _c('li', [_vm._v("- Mehrfach Filter für PLZ angepasst : Bsp: \"1,8\" funktioniert jetzt für Steiermark+Wien")]), _c('li', [_vm._v("- Bericht Felder: Im Kopfteil bei Wegklicken (\"x\") kommt im pdf ein \"-\"")]), _c('li', [_vm._v("- Allianz Map: Zoom besser eingestellt (so lassen sich die Marker auf den ersten Blick besser finden)")]), _c('li', [_vm._v("- Allianz Map: automatisch Ein/Ausblenden der Marker erst ab 500 Akte Anzahl, davor immer sichtar")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.3")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.02.2020")]), _c('ul', [_c('li', [_vm._v("- Fehler bei Photo Upload behoben (für alle Android Tablet Modelle angepasst)")]), _c('li', [_vm._v("- PolNr in Akt Kopf eingebaut")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.2")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.02.2020")]), _c('ul', [_c('li', [_vm._v("- CRM - Benutzer angepasst (einige Benutzer hatten Probleme beim Anzeigen von Akten)")]), _c('li', [_vm._v("- CRM - Sharepoint app fixed redirect")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.2.1")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.02.2020")]), _c('ul', [_c('li', [_vm._v("- User Settings behoben")]), _c('li', [_vm._v("- Interval für Reload auf 5 Minuten eingestellt")]), _c('li', [_vm._v("- Upload Liste : Fehler bei Datum sortierter Liste behoben")]), _c('li', [_vm._v("- Vorlage für Akt angepasst (kein Speicher button, wenn nichts geändert)")]), _c('li', [_vm._v("- logging tool eingeschalten (LogRocket)")]), _c('li', [_vm._v("- URL encode für Dateien im Bericht")]), _c('li', [_vm._v("- PolNr im Bericht wird jetzt aufgenommen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h2', [_vm._v("V 1.2.0")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("23.02.2020")]), _c('ul', [_c('li', {
    staticClass: "bold _redp"
  }, [_vm._v("- Komplett neue App! Neu Anmelden erforderlich!")]), _c('li', {
    staticClass: "bold _redp"
  }, [_vm._v("- Neuer server (Digital Ocean)")]), _c('li', {
    staticClass: "bold"
  }, [_vm._v("- Dateien werden nun in Sharepoint archiviert!")]), _c('li', [_vm._v("- Neues Layout System")]), _c('li', [_vm._v("- Verbesserter Bilder Offline Speicher")]), _c('li', [_vm._v("- User Rollen/Rechte verbessert")]), _c('li', [_vm._v("- Eigene Einstellungen eingefügt/angepasst")]), _c('li', [_vm._v("- HV: Anzahl der Akte sofort anpassbar")]), _c('li', [_vm._v("- bearbeitender SV Status hinzugefügt")]), _c('li', [_vm._v("- mehrere bugs behoben")]), _c('li', [_vm._v("- Neu: Journal / History (Felder werden mit geloggt!)")]), _c('li', [_vm._v("- Wenn HV was ausgefüllt hat, wird der Akt auch aktualisiert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h2', {
    staticClass: "_redp"
  }, [_vm._v("🐳🐳🐳 NEUE VERSION : V 1.2.0")])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.19c")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.02.2020")]), _c('ul', [_c('li', [_vm._v("- Mehrfach filter: z.B. in den Filtern eingeben (E1,H9)")]), _c('li', [_vm._v("- Vorbereitung für Update des Chrome Browsers am 17. März 2020: automatische Bilder-Rotation (für .jpg) Berücksichtigung")]), _c('li', [_vm._v("-")]), _c('li', [_vm._v("- TODO: .msg Dateien lesen")]), _c('li', [_vm._v("- ? (TODO: Grundbuchauszugscheck automatisch download (Schnittstelle oder Erinnerung)")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- TODO: bearbeitender SV - Status (Besicht./Massener./Ber.f.EHWer.)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.19b")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.02.2020")]), _c('ul', [_c('li', [_vm._v("- \"Tram-Traversendecke\" hinzugefügt, \"Betonbauweise\" zu \"Stahlbetonbauweise\"")]), _c('li', [_vm._v("- Karten Links im Notizen Reiter")]), _c('li', [_vm._v("- Variante 3 für Berechnungstabelle")]), _c('li', [_vm._v("- \"bearbeitender SV\" hinzugefügt")]), _c('li', [_vm._v("-")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- TODO: Excel 3. Veriante (Spalten variabel)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.19a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12.02.2020")]), _c('ul', [_c('li', [_vm._v("- \"Natursteintreppe mit schmiedeeisernem Geländer\" hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.19")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("31.01.2020")]), _c('ul', [_c('li', {
    staticClass: "bold _redp"
  }, [_vm._v("- neue Datenbank Version NEU ANMELDEN ERFORDERLICH")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.18")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.01.2020")]), _c('ul', [_c('li', [_vm._v("- PLZ Filter in Alle Allianz Akte")]), _c('li', [_vm._v("- FIX: Excel-Export Status für HV")]), _c('li', [_vm._v("- Vorbereitungen für neue Server API V2")]), _c('li', [_vm._v("- Tablet Fotos hochladen: mehrere selektierbar")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.17")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.01.2020")]), _c('ul', [_c('li', [_vm._v("- Filterfelder wie in Excel")]), _c('li', [_vm._v("- TODO: Excel 3. Veriante (Spalten variabel)")]), _c('li', [_vm._v("- TODO: .msg Dateien lesen")]), _c('li', [_vm._v("- ? (TODO: Grundbuchauszugscheck automatisch download (Schnittstelle oder Erinnerung)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.16")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("13.01.2020")]), _c('ul', [_c('li', [_vm._v("- Zusatzfilter nach PLZ/ORT; auch in Export berücksichtigt")]), _c('li', [_vm._v("- Allianz Downloads (Eigener Account für Allianz Mitarbeiter)")]), _c('li', [_vm._v("- Besichtigungsdatum in Tabelle hinzugefügt")]), _c('li', [_vm._v("- Dateien Uploads in Firefox gehen noch nicht")]), _c('li', [_vm._v("- Bilder offline hochladen verbessert")]), _c('li', [_vm._v("- XLS/CSV Nach Vorlage formatiert")]), _c('li', [_vm._v("- Besichtigun zu Bes.Kontakt umbenannt")]), _c('li', [_vm._v("- SV filterbar in Tabelle")]), _c('li', [_vm._v("-")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- TODO: Besichtigungsdatum im Bericht übernemen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.15")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("07.01.2020")]), _c('ul', [_c('li', [_vm._v("- Manche Bilder wurden nicht komprimiert- nachträglich komprimiert!")]), _c('li', [_vm._v("- Popup verschwindet jetzt nach Bilder Uploads (Windows - Problem)")]), _c('li', [_vm._v("- Adresse mitlaufend")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.14")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.12.2019")]), _c('ul', [_c('li', [_vm._v("- neue 2. Möglichkeit Excel berechnung (nach WNFl.) --> BITTE PRÜFEN OB ZAHLEN UND LAYOUT PASSEN!!")]), _c('li', [_vm._v("- Uploads nach Datum sortieren")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.13")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("10.12.2019")]), _c('ul', [_c('li', [_vm._v("- FIX: Gewerbe bei JA: + Keine Angabe in Prozent")]), _c('li', [_vm._v("- FIX: Status Filter")]), _c('li', [_vm._v("- FIX: Dateien doc hvw")]), _c('li', [_vm._v("- FIX: Dateien Meldung bei Bild hochgeladen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.12")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("5.12.2019")]), _c('ul', [_c('li', [_vm._v("- CSV Export in \"Alle Allianz Akte\"")]), _c('li', [_vm._v("- XLSX Export in \"Alle Allianz Akte\"")]), _c('li', [_vm._v("- \"keine Angabe\" bei Bericht Feldern")]), _c('li', [_vm._v("- Zip download ohne Popup (Windows Problem!!) (geändert aber noch nicht an Windows getestet)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.11")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("3.12.2019")]), _c('ul', [_c('li', [_vm._v("- Neue Rolle \"SVm\" : SV \"Minus\" : SV sieht beim Einloggen nur seine eigene Akte")]), _c('li', [_vm._v("- Meine Akte\" funktioniert JETZT")]), _c('li', [_vm._v("- Name in Navigation ( wer ist gerade angemeldete!!)")]), _c('li', [_vm._v("- ZU große Bilder bei HV Daten werden nicht mehr \"vor\"geladen")]), _c('li', [_vm._v("- kleinere \"Neue Version\" Leiste")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.10")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("2.12.2019")]), _c('ul', [_c('li', [_vm._v("- Bilder Zoom verbessert (läuft jetzt schneller)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.9")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.11.2019 - 2.12.2019")]), _c('ul', [_c('li', [_vm._v("Links nö/steiermark/... Karten einblenden (noch nicht alle dynamisch!)")]), _c('li', [_vm._v("Bilder öffnen Bericht")]), _c('li', [_vm._v("Bilder \"Foto machen\" mitlaufend")]), _c('li', [_vm._v("Bilder zoomen in Vorschau")]), _c('li', [_vm._v("Bewerbe: % -> \"keine Angabe\" hinzufügen")]), _c('li', [_vm._v("Betriebsart \"+\" hinzufügen")]), _c('li', [_vm._v("Gebäudeart \"+\" hinzufügen")]), _c('li', [_vm._v("Handlauf/Geländer zu Stiegen hinzufügen")]), _c('li', [_vm._v("schmiedee. -> schmiedeeisern ausschreiben")]), _c('li', [_vm._v("Status \"Z1\" für Allianz sichtbar")]), _c('li', [_vm._v("falsche Schrtiftarten im Bericht (vorläufig behoben -> Text aber noch nicht markierbar!)")]), _c('li', [_vm._v("Rückstellbegründung Status + Notiz (von A.Pietschnig mit Status gelöst)")]), _c('li', [_vm._v("Status (H9) hervorheben (orange)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.8")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.11.2019")]), _c('ul', [_c('li', [_vm._v("- Bilder eingeblendet in Bericht")]), _c('li', [_vm._v("- Zip Date für alle Dateien runterladen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.7")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("21.11.2019")]), _c('ul', [_c('li', [_vm._v("- Sammelaktion- Fehler behoben! - HV zuweisen geht nun wieder")]), _c('li', [_vm._v("- Fotos in Bericht erstellen (mit Tablet!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.6")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("20.11.2019")]), _c('ul', [_c('li', [_vm._v("- Bilder in HVW/Dokumente werden auch angezeigt")]), _c('li', [_vm._v("- HV zuweisen ausgebessert (unbekannter Fehler noch vorhanden!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.4, V 1.1.5")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.11.2019")]), _c('ul', [_c('li', [_vm._v("- Selektierte Aufrufe (Nur meine Akte / nur Allianz Akte)")]), _c('li', [_vm._v("- Fehler beim HV anlegen behoben")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.2, V 1.1.3")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.11.2019")]), _c('ul', [_c('li', [_vm._v("- Status Filter Fehler behoben!")]), _c('li', [_vm._v("- Schnellere Aufrufe durch cached Daten")]), _c('li', [_vm._v("- neues Bilddatei: _t_.webp (thumbnail!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.1.1")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15-18.11.2019")]), _c('ul', [_c('li', {
    staticClass: "bold"
  }, [_vm._v("- Code massiv aufgeräumt für Speichern / Aufrufen von Daten von Firebase")]), _c('li', {
    staticClass: "bold"
  }, [_vm._v("- Mehrere Funktionen optimiert und ausgebaut")]), _c('li', [_vm._v("- Bilder Kompression wesentlich beschleunigt -> WEBP format")]), _c('li', [_vm._v("- Bilder Algorithmus für bestehende Daten umformatieren")]), _c('li', [_vm._v("- Bilder/ Dateien nacheinander anschauen")]), _c('li', [_vm._v("- Bilder Fehler ausgebessert: Rotation für Tablet automatisch erkannt")]), _c('li', [_vm._v("- Bilder Direkter Upload bei Tablet")]), _c('li', [_vm._v("- Version automatisiert (wenn das Gerät eine neuere hat -> keine Meldung!)")]), _c('li', [_vm._v("- Fehler: Datei löschen bei HV behoben")]), _c('li', [_vm._v("- Entwicklungs Umgebung Kopie erstellt (zum besseren Testen an nicht-live Daten)")]), _c('li', [_vm._v("- Nur SV-zugehörige Dateien werden zwischengespeichert, andere werden bei offline nicht angezeigt")]), _c('li', [_vm._v("- Journal wird nun für Akte und Bericht abgespeichert!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h2', {
    staticClass: "_redp"
  }, [_vm._v("🐳🐳🐳 NEUE VERSION : V 1.1.0")])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.54")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.11.2019")]), _c('ul', [_c('li', [_vm._v("- Dateien-Check in Allianz Liste")]), _c('li', [_vm._v("- Google-Map 3D-Link im Akt (für Stockwerk-Anzahl Ermittlung)")]), _c('li', [_vm._v("- Allianz List etwas schneller gemacht")]), _c('li', [_vm._v("- Reiter-Leiste fixiert")]), _c('li', [_vm._v("- Bilder am Tablet verbessert (direkter Upload nach Photo erstellen)")]), _c('li', [_vm._v("- ⭐ Letzte uploads anzeigen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.53")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("12.11.2019")]), _c('ul', [_c('li', [_vm._v("- PDF: ausgetauscht Bearbeiter/Polnr/ConsenseNR")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.51/52")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.11.2019")]), _c('ul', [_c('li', [_vm._v("- Download Liste für Bewertungen hinzugefügt")]), _c('li', [_vm._v("- Vorlagen Bilder - Fehler behoben")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.49/50")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.11.2019")]), _c('ul', [_c('li', [_vm._v("- DATENBANK PROBLEM - ALTERNATIVE DB ERSTELLT")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.48")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08/09.11.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐ Filter zurückgebracht")]), _c('li', [_vm._v("- Bilder Komprimieren verbessert (schneller)")]), _c('li', [_vm._v("- Bilder Ansichten (wieder) hinzugefügt")]), _c('li', [_vm._v("- Lageplan Bilder: 2/A4 -Seite Option")]), _c('li', [_vm._v("- Erdgeschoss -> Erdniveau ausgebessert")]), _c('li', [_vm._v("- pdf Seiten hinzugefügt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.47")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("07.11.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐ Webapp Client optimiert - Verzögerung beim Starten deutlich reduziert (von ca 8-12s auf ca. 1-3s)")]), _c('li', [_vm._v("- kleinere Bericht Fehler ausgebessert! (Benutzer freundlichere/klarere Buttons und Meldungen)")]), _c('li', [_vm._v("- Fehler im Bericht (Geschosszahlen angepasst)")]), _c('li', [_vm._v("- Dach aus Berechnung rausgenommen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.46")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("04/05.11.2019")]), _c('ul', [_c('li', [_vm._v("- nach HVW filtern in Alle Akte - Sortieren/Filtern in HVW Tab")]), _c('li', [_vm._v("- Tablet hochkant Suche eingeblendet")]), _c('li', [_vm._v("- Allianz Akt Termin: Zeit jetzt auch einstellbar")]), _c('li', [_vm._v("- Bericht: Nutzungsdaten werden in EXCEL übernommen")]), _c('li', [_vm._v("- Bericht: Anzahl der Geschosse in Nutzung übernehmen")]), _c('li', [_vm._v("- Bericht: \"Zurücksetzen\" Button\"")]), _c('li', [_vm._v("- Bericht: mehrere Warnungen bevor etwas endgültiges passiert!")]), _c('li', [_vm._v("- Bericht: Bilder löschen/hinzufügen")]), _c('li', [_vm._v("- Bericht: Lageplan-Bild auf ganze A4 Seite angepasst")]), _c('li', [_vm._v("- Bericht: Gewerbe Mehrfachauswahl")]), _c('li', [_vm._v("- Bericht: neue Felder in Bericht-Vorlage")]), _c('li', [_vm._v("- HV-Link repariert! (Rechte Problem)")]), _c('li', [_vm._v("- HV-Link Texte ausgetauscht")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.44/45")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.10.2019")]), _c('ul', [_c('li', [_vm._v("- Berechnungstabelle (Excel) im pdf Bericht ausgebessert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.43")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("28.10.2019")]), _c('ul', [_c('li', [_vm._v("- HV anlegen repariert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.41/42/42a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("25.10.2019 / 26.10.2019")]), _c('ul', [_c('li', [_vm._v("- Name im Akt")]), _c('li', [_vm._v("- Error logger (client side to server) = noch am Testen! (Part3)")]), _c('li', [_vm._v("- Design Fehler behoben")]), _c('li', [_vm._v("- Firebase Aufrufe kombiniert (schnelerre Ladezeit)")]), _c('li', [_vm._v("- IE indexeddb - Datenbank ausgeschalten")]), _c('li', [_vm._v("- Erinnerungen verbessert")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.40A")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.10.2019")]), _c('ul', [_c('li', [_vm._v("- Filter nach Status mit \"s:\" vorgestellt")]), _c('li', [_vm._v("- Error logger (client side to server) = noch am Testen! (Part2)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.40")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.10.2019")]), _c('ul', [_c('li', [_vm._v("- Status(HV) Fehler behoben")]), _c('li', [_vm._v("- Links ausgebessert")]), _c('li', [_vm._v("- Datei löschen im HV-Link")]), _c('li', [_vm._v("- Error logger (client side to server) = noch am Testen! (Part1)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.39a")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.10.2019")]), _c('ul', [_c('li', [_vm._v("- TIFF Support")]), _c('li', [_vm._v("- Kompression ausgeschalten (vorübergehend)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.37-39")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("21-22.10.2019")]), _c('ul', [_c('li', [_vm._v("- INDEXEDDB Datenbank als Offline Speicher statt LOCAL STORAGE!! -> 50MB statt 5MB")]), _c('li', [_vm._v("- Status für HVW")]), _c('li', [_vm._v("- HVW Unterseiten")]), _c('li', [_vm._v("- Daten bereinigen (wenn Akte nicht bei HV angezeigt werden)")]), _c('li', [_vm._v("- Status in HVW Liste")]), _c('li', [_vm._v("- kleinere Fehler behoben")]), _c('li', [_vm._v("- Sammellinks für HV Fehler behoben / bessere Fehler-Darstellung")]), _c('li', [_vm._v("- ErinnerungenListe angefangen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.36")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18-20.10.2019")]), _c('ul', [_c('li', [_vm._v("- Seiten blättern optimiert")]), _c('li', [_vm._v("- Tabellenansicht optimiert")]), _c('li', [_vm._v("- Sammelaktionen verbessert")]), _c('li', [_vm._v("- Kontakte verbessert")]), _c('li', [_vm._v("- Erinnerungen hinzugefügt")]), _c('li', [_vm._v("- Status hizugefügt")]), _c('li', [_vm._v("- schnelleres Laden (Map Anzeige!)")]), _c('li', [_vm._v("- Bugfixes in Datenbank/server")]), _c('li', [_vm._v("- AdressenListe für Makler")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.34 / 35")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("14.10.2019")]), _c('ul', [_c('li', [_vm._v("- bugfuxes (Link für HVW / schnellerer Aufrufe/...)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.32 / 33")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.10.2019")]), _c('ul', [_c('li', [_vm._v("- Sturmschaden mit Bilder")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.32 / 33")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.10.2019")]), _c('ul', [_c('li', [_vm._v("- HVW Links Fehler ausgebessert / schnellere Aufrufe / Design angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.31")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.10.2019")]), _c('ul', [_c('li', [_vm._v("- Schnellere Suche in Allianz Akte")]), _c('li', [_vm._v("- Sturmschaden Experimental eingearbeitet (Fragen / Excel / PDFBericht)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.30")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("02.10.2019")]), _c('ul', [_c('li', [_vm._v("- Bericht auf mehrere Gebäude/Grundstück(Objekt) angepasst")]), _c('li', [_vm._v("- HVW Sammel-Link")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.29")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.09.2019")]), _c('ul', [_c('li', [_vm._v("- Bericht für ein Gebäude/Grundstücke angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.28")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("30.09.2019")]), _c('ul', [_c('li', [_vm._v("- Neue Liste eingespielt")]), _c('li', [_vm._v("- auto. Map Koordinaten eingetragen")]), _c('li', [_vm._v("- Optimieren der Datenbank Leistungen (noch in Bearbeitung => mache Funktionen sind noch nicht aktiv(Bericht/HVW Links))")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.27")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.09.2019")]), _c('ul', [_c('li', [_vm._v("- Neue Daten eingespielt (in Vorbereitung)")]), _c('li', [_vm._v("- diverse Verbesseungen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.26")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.09.2019")]), _c('ul', [_c('li', [_vm._v("- improved/fixed Sharepoint")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.25")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.09.2019")]), _c('ul', [_c('li', [_vm._v("- better device detection")]), _c('li', [_vm._v("- Android fix refresh Version")]), _c('li', [_vm._v("- Edge Uploads working!")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.24")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.09.2019")]), _c('ul', [_c('li', [_vm._v("- 🦀kleinerer Bugfixes")]), _c('li', [_vm._v("- ⭐⭐⭐ Datensätze von ALLIANZ BEREINIGT")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.23")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.09.2019")]), _c('ul', [_c('li', [_vm._v("- Aktualisierung (Refresh) in Tablet")]), _c('li', [_vm._v("- Sonstige Aussenanlagen hinzufügen/löschen")]), _c('li', [_vm._v("- Geldbeträge auf Tausender runden")]), _c('li', [_vm._v("- Vorlage für Vollmacht in HVW Link")]), _c('li', [_vm._v("- PDF: Tabellen statt Listen")]), _c('li', [_vm._v("- PDF: Bericht: autom. HVW Daten laden")]), _c('li', [_vm._v("- PDF: logo in pdf!! ausgebessert (svg-gradient not printable!)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.22")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("07.09.2019")]), _c('ul', [_c('li', [_vm._v("- upload button in Sharepoint")]), _c('li', [_vm._v("- sharepoint in SV ins crm")]), _c('li', [_vm._v("- pdf-Bericht Komma Punkt Problem")]), _c('li', [_vm._v("- pdf-Bericht Entscheidungs tool für HVW Daten")]), _c('li', [_vm._v("- HVW: Design angepasst")]), _c('li', [_vm._v("- HVW: sämtliche Daten hinzugefügt")]), _c('li', [_vm._v("- PolNr (mit Link) nicht ausblenden in Tabelle")]), _c('li', [_vm._v("- Bilder drehen raus!! (zu fehlerbehaftet!!)")]), _c('li', [_vm._v("- Allianz Daten modified hinzugefügt /auto-sortieren")]), _c('li', [_vm._v("- sonstige Eigenschaften in einfügen, wenn nicht vorgegeben")]), _c('li', [_vm._v("- Kontakte hinzugefügt (Makler HVW Eigentümer KontaktBes. )")]), _c('li', [_vm._v("- Daten von HVW in Bericht einbinden (noch nicht pdf!)")]), _c('li', [_vm._v("- Tablet neue Version - > \"App neu starten\" - Info")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.21")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("05.09.2019")]), _c('ul', [_c('li', [_vm._v("- Allianz : Tabelle /Liste Umschalter")]), _c('li', [_vm._v("- SV können zwischen \"Meine Akte\" und \"Alle Akte\" einsehen")]), _c('li', [_vm._v("- Allgemeines Info Feld in Allianz Akt")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.20")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("31.08.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐ pdf-generator nach Vorlage")]), _c('li', [_vm._v("- ⭐ Karten von google maps automatische generiert + gespeichert + in Bericht eingefügt")]), _c('li', [_vm._v("- Karten WIEN GV experimentell eingebunden")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.19")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.08.2019")]), _c('ul', [_c('li', [_vm._v("- angefangen: Bilder werden auf tablets offline zwischengespeichert, falls Internet nicht vorhanden (Keller!!)")]), _c('li', [_vm._v("- KAMPTAL Akte hinzugefügt")]), _c('li', [_vm._v("- Allianz Daten: bessere Liste - Suche angepasst")]), _c('li', [_vm._v("- Zusatzfeld eingefügt, falls Koordinaten nicht gefunden werden")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.18")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.08.2019")]), _c('ul', [_c('li', [_vm._v("- Bilder Uploads verbessert (bessere Unterteilung für Größen bei pdf-Erstellung)")]), _c('li', [_vm._v("- Bericht + PDF-Bericht für Allianz erstellen nach Vorgaben")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.17")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("19.08.2019")]), _c('ul', [_c('li', [_vm._v("- Dateien in Allianz Akte hinzugefügt")]), _c('li', [_vm._v("- PDF-Bericht erstellen (Vorversion)")]), _c('li', [_vm._v("- Allianz Akte aufräumen")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.16")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("05.08.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐ bugfix: SV-Urlaubs-Kalender zeigt jetzt das richtige Datum an")]), _c('li', [_vm._v("- ⭐ improvement: Fürs Besichtigungsdatum jetzt Zeitperiode statt Datum eingeben")]), _c('li', [_vm._v("- ⭐ Routenplanner: Bei Eingabe wird Reihenfolge wird beibehalten")]), _c('li', [_vm._v("- TODO: Sortierung bei SV-Kalender noch in Bearbeitung")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.15")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.07.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐ Journal hinzugefügt ( + Ereignisse mapping)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.14")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("15.07.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐ Gf-Tabelle angepasst - Aufwände jetzt speicherbar")]), _c('li', [_vm._v("- ⭐ Termine Design angepasst")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.13")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("11.07.2019")]), _c('ul', [_c('li', [_vm._v("- Fehlende Info zu Details hinzugefügt")]), _c('li', [_vm._v("- Adressen Kontrolle im Akt Details")]), _c('li', [_vm._v("- angepasste Rechte für hochgeladene Dateien der HVW")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.12")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("09.07.2019")]), _c('ul', [_c('li', [_vm._v("- Sharepoint Fenster im crm angepasst")]), _c('li', [_vm._v("- Dashboard mit Kalender angepasst")]), _c('li', [_vm._v("- neues Layout für Termine in Akt Details")]), _c('li', [_vm._v("- neue Allianz Akte aktualisiert")]), _c('li', [_vm._v("- Allianz-Ansichten angepasst (noch in Bearbeitung...)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.11")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("08.07.2019")]), _c('ul', [_c('li', [_vm._v("- Nach längerer Inaktivität Bestätigungsmeldung (Resourcen sparen)")]), _c('li', [_vm._v("- Server Optimierungen + bugfixes")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.10")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("06.07.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐️ Neuen Aufwand hinzufügen")]), _c('li', [_vm._v("- ⭐️ Aufwand löschen")]), _c('li', [_vm._v("- ⭐️ Aufwand updaten")]), _c('li', [_vm._v("- ⭐️ getrennte Aufwand-Ansicht für GF un SV")]), _c('li', [_vm._v("- \"vertraulich\" nur für GF sichtbar")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.9")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("03.07.2019-05.07.2019")]), _c('ul', [_c('li', [_vm._v("-️️️ ⭐️ Save \"Aufwand\"")]), _c('li', [_vm._v("-️️️ ⭐️ Save \"Akt - Termine\"")]), _c('li', [_vm._v("- reset Token for CRM user")]), _c('li', [_vm._v("- fixed Details for changed \"Akte\"")]), _c('li', [_vm._v("- shows Error if \"Akt\" does not exist")]), _c('li', [_vm._v("- combine cached refresh for CRM states")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.8")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("26.06.2019")]), _c('ul', [_c('li', [_vm._v("- added dates for .msg files")]), _c('li', [_vm._v("- fix: date on SV-Calendar")]), _c('li', [_vm._v("- started Calendar in details-view")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.7")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("24.06.2019")]), _c('ul', [_c('li', [_vm._v("- ️️️⭐️ shows if version is too old")]), _c('li', [_vm._v("- ⭐️ Dashboard - admin SV Simulator...")]), _c('li', [_vm._v("- prepared dates for details")]), _c('li', [_vm._v("- table/list switcher")]), _c('li', [_vm._v("- changed preview from 5MB to 20MB file size")]), _c('li', [_vm._v("- added -docm files to preview")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.6")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("22.06.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐️ now possible: delete files in sharepoint")]), _c('li', [_vm._v("- ⭐️ improved SP Files UI")]), _c('li', [_vm._v("- added admin reseter (still todo: auto refresher after reset)")]), _c('li', [_vm._v("- added backup for ALLIANZ (DB + FILES) (todo: make a cron job for that)")]), _c('li', [_vm._v("- temporary file cleaner on server for SP files (cron job)")]), _c('li', [_vm._v("- added .xls, .xlsx, .log ,.docm to Previews Formats")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.5")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("21.06.2019")]), _c('ul', [_c('li', [_vm._v("- ⭐️ Preview for Files in List details")]), _c('li', [_vm._v("- ⭐️ .msg Support files Preview")]), _c('li', [_vm._v("- ⭐️ .doc/.docx Support files Preview")]), _c('li', [_vm._v("- check for internet/fb connections + Notification")]), _c('li', [_vm._v("- added SuperAdmin (for backups)")]), _c('li', [_vm._v("- started building dashboard")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.4")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("18.06.2019")]), _c('ul', [_c('li', [_vm._v("- Added ErrorPrinter to logs")]), _c('li', [_vm._v("- Automatically logout after consensetoken expires (after 3 days)")]), _c('li', [_vm._v("- logout for user after role change")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.3rc")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.06.2019")]), _c('ul', [_c('li', [_vm._v("- Internet Explorer 11 Support: IE11 works now (older than 11 not tested)")])])]), _c('li', {
    staticClass: "vlist"
  }, [_c('h4', [_vm._v("V 1.0.2rc")]), _c('div', {
    staticClass: "date"
  }, [_vm._v("17.06.2019")]), _c('ul', [_c('li', [_vm._v("- Server optimized:"), _c('ul', {
    staticClass: "padd"
  }, [_c('li', [_vm._v("- faster and less calls")]), _c('li', [_vm._v("- improved Authentication for userDataFb")]), _c('li', [_vm._v("- refactored (cleaned up unsed code)")])])]), _c('li', [_vm._v("- new role \"GF\" for \"Geschäftsführung\"")]), _c('li', [_vm._v("- added \"Expend\" calls to server (not client anymore = faster response)")]), _c('li', [_vm._v("- Sharepoint added multiple files to upload")]), _c('li', [_vm._v("- SV-role gets only its own \"Akte\"")]), _c('li', [_vm._v("- improved redirectd for SP in CRM SV-Maske (is faster now and should work (tests needed))")]), _c('li', [_vm._v("- got keys for \"Tätigkeit\" and \"Einheit\" in \"Aufwand\" (static - need to be dynamic)")]), _c('li', [_vm._v("- improved mobile menu")]), _c('li', [_vm._v("- add changelogs :)")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('ul', {
    staticClass: "changelogs"
  }, [_c('li', [_c('h5', [_vm._v("technical stuff")])]), _c('li', [_vm._v("- copy logs after build")]), _c('li', [_vm._v("- \"report a bug\" form for user")]), _c('li', [_vm._v("- add auto version control from package.json")]), _c('li', [_vm._v("- temporary token for files (security patch)")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- hide \"vertraulich\"")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- Improve Sharepoint iframe in CRM")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- check for internet/fb connections")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- daily Backup for ALLIANZ Akte (DB + FILES)")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- add temporary file cleaner on server for SP files")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- vuex-persist-sync on multiple tabs in browser")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- no relogin on role change for upper roles")]), _c('li', {
    staticClass: "done"
  }, [_vm._v("- combine cached refresh for CRM states: sparten/status/details/tätigkeit/einheit/...")]), _c('li', [_c('h5', [_vm._v("user workflow stuff")])]), _c('li', [_vm._v("- Zeitkarte creator on server")]), _c('li', [_vm._v("- check emails sender on server")]), _c('li', [_vm._v("- dragdrop \".msg\" files in SP")]), _c('li', [_vm._v("- add \"Dashboard\" for all user")]), _c('li', [_vm._v("- \"Gutachten / Bewertung erstellen\" -> pdf")]), _c('li', [_vm._v("- Dashboard fertigstellen")])]);
}];
export { render, staticRenderFns };